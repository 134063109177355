import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import { Section } from '../components/section';
import { Anchors } from '../components/navigation';
import styles from './index.module.scss';
import MainImg from '../images/main.inline.svg';
import AdviseImg from '../images/advise.inline.svg';
import BuildImg from '../images/build.inline.svg';
import DesignImg from '../images/design.inline.svg';
import MaintainImg from '../images/maintain.inline.svg';

const IndexPage = ({ location }: { location: Location }) => (
  <Layout location={location}>
    <SEO title="SimpleSoft software house" description="business applications, mobile and web app, application creations, software house, app development" />
    <Section anchor={Anchors.intro} className={styles.intro}>
      <h1>Dedicated software solutions</h1>
      <div>
        <div className={styles.infoImage}>
          {/* <Image /> */}
          <MainImg width="100%" height="100%" />
        </div>
        <div className={styles.desc}>
          <p>
            We are a young company created by people whose passion and work is software creation and applications development.
            We don't want you to get bored with terminologies and technologies we use, but stay assured that all tools will be carefully selected depending on the project's needs and possible problems.
        </p>
          <p>Depending on your project demands we will either create all required solutions from the beginning or extend already existing ones.</p>
          <p>
            Starting from laying down specific requirements, through development and advice at all stages to maintenance and constant help - we offer all services that you need for long-term and short-term projects. No job is too small for us!
        </p>
        </div>
      </div>
    </Section>
    <Section anchor={Anchors.services} className={styles.services}>
      <h1>Services</h1>
      <div className={styles.service}>
        <h3>Advice </h3>
        <div>
          <AdviseImg />
          <p>
            All projects start with an idea.
            We will help to transform that idea into the project's requirement and strategy.
            We advise on choosing the correct technology stack and an external services as well as set up the timeline of the project.
        </p>
        </div>
      </div>
      <div className={styles.service}>
        <h3>Design</h3>
        <div>
          <DesignImg />
          <p>
            Good design is much more than the look itself.
            Intuitive, accessible, flexible and functional interface is something that may decide about success of the whole project
        </p>
        </div>
      </div>
      <div className={styles.service}>
        <h3>Development</h3>
        <div>
          <BuildImg />
          <p>
            Development is the core to all our activities.
            We create and develop our applications making sure that they will succeed in the future.
            Thanks to agile process and automation you are able to overview any changes we made to the project.
            This option enable us to react on all of your requests quickly.
        </p>
        </div>
      </div>
      <div className={styles.service}>
        <h3>Maintenance</h3>
        <div>
          <MaintainImg />
          <p>
            Our applications are designed to be able to scale up when necessary and be active for a long time taking into consideration that requirements do change all the time.
            We assist you with maintaining your project to keep it up and running.
        </p>
        </div>
      </div>

      <div className={styles.tech}>
        <div className={styles.scope}>
          <div>
            <h4>Scope</h4>
            <ul>
              <li>Business applications</li>
              <li>Cloud Solutions</li>
              <li>Web appliactions / Websites</li>
              <li>Mobile Apps</li>
            </ul>
          </div>
        </div>
        <div className={styles.stack}>
          <h4>Development Stack</h4>
          <ul>
            <li>C#</li>
            <li>F#</li>
            <li>TypeScript</li>
            <li>JavaScript</li>
            <li>React</li>
            <li>Angular</li>
            <li>.Net Core</li>
            <li>Node.js</li>
            <li>React Native</li>
            <li>Python</li>
            <li>MSSql</li>
            <li>PostgreSQL</li>
            <li>Azure</li>
          </ul>
        </div>
      </div>
    </Section>
    <Section anchor={Anchors.principles}>
      <h1>Our principles</h1>
      <div className={styles.principles}>
        <div>
          <h3>Short feedback loop.</h3>
          <p>
            Progress of our work on your project is visible to you all the time. This allows you to feedback on any part of the process accordingly.
          </p>
        </div>
        <div>
          <h3>Automation</h3>
          <p>
            We strongly believe that software creation process should be automated wherever possible. We developed our own way of delivering daily changes in a safe and secure way to minimize any potential risks
            {/* {This reduces costs and allow us to give best quality product.}  */}
          </p>
        </div>
        <div>
          <h3>Individual approach</h3>
          <p>
            We always adopt to your needs, budget and any limits. Every development is preceded with throughout research and analysis.
          </p>
        </div>
      </div>
    </Section>
    <Section anchor={Anchors.contact}>
      <h1>Contact</h1>
      <div className={styles.contact}>
        SimpleSoft<br />
        Robotnicza 72/225<br />
        53-608 Wrocław<br />
        Poland<br />
        <a href="tel://+48 697 321 812">+48 697 321 812</a>
        <br />
        <a href="mailto://contact@simplesoft.tech">contact@simplesoft.tech</a>
      </div>
    </Section>
    <Section anchor={Anchors.career}>
      <h1>Career</h1>
      <div className={styles.career}>
        <h3>We are always looking for motivated and dedicated candidates to join our team.</h3>
        <p>If you are looking for a fantastic opportunities to expand your knowledge and skills please send your CV or web-based profile to <a href="mailto://job@simplesoft.tech">job@simplesoft.tech</a></p>
        <h3>Current positions</h3>
        <ul>
          <li>
            <a href="/dotnet-developer">.NET developer</a>
          </li>
          <li>
            <a href="/devops">Devops</a>
          </li>
          <li>
            <a href="/trainee">Trainee</a>
          </li>
        </ul>
      </div>
    </Section>
    {/* <AniLink cover bg="#cce" duration={0.7} to="/page-2/">
      Go to page 2
    </AniLink> */}
  </Layout>
);

export default IndexPage;
